import { ref, computed } from '@vue/composition-api'
import {
  groupBy, isEmpty, uniqBy, sortBy, cloneDeep, min, max,
  isArray,
} from 'lodash-es'
import debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import { SKYBOSS_FILTER_REGEX } from '@/constants/flight'
import { apiBooking } from '@/api/'
import { iataCodeVJ2, iataCodeVJ3, isDomesticFlight } from '@/constants/selectOptions'
import { getUserData } from '@/api/auth/utils'
import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import {
  calculatePromotion, comparePoint, compareTrip, mixCountryTrips, mixFlightTrips,
  mixRegionTrips,
} from '@/@core/utils/utils'
import store from '@/store'
import { removeAccents } from '@/@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default function useBookingHandle() {
  const TIME_LIMIT_REQUEST_SEARCH_ID = 120 // đang để tạm là 1 phút nếu ko tìm được thì dừng call và ko show ra thông báo gì cả
  const FLIGHT_APP_STORE_MODULE_NAME = 'app-flight-v2'
  const { toastError } = useToast()
  const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
  const intervalIDs = []
  const clearIntervalIDs = () => {
    intervalIDs.forEach(id => {
      clearInterval(id)
    })
  }

  function resetStore(isRemoveFeeServiceData = false) {
    return store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/resetStore`, isRemoveFeeServiceData)
  }

  const getAirportByAirportCode = airportCode => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](airportCode)

  const getAirportsByCodeList = airportCodeList => store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchAirportsByCodes`, airportCodeList)

  // ANCHOR Search
  const searchFlightv2 = async (payload, isSaveLocalStorage = false) => {
    // Clear interval cũ để ko bị duplicate search
    clearIntervalIDs()

    if (payload && ['OW', 'MC'].includes(payload.type)) {
      payload.flights.forEach(item => delete item.returnDate)

      if (['OW'].includes(payload.type)) { // nếu OW bỏ GHK (search nhanh)
        payload.isGroupedItineraryResponse = undefined
      }
    }

    if (isSaveLocalStorage) {
      localStorage.setItem('searchFlight', JSON.stringify(payload))
    }
    // thay airlines = source
    if (payload?.airlines?.length) {
      payload.sources = payload.airlines.map(item => item === 'VN' ? '1S' : item)
    } else if (payload.sources && (!payload.airlines || (payload.airlines && isEmpty(payload.airlines)))) {
      delete payload.sources // xoá source nếu k có airlines
    }

    // delete payload.airlines // chuyển sang store xoá

    if (!payload.permittedCarriers?.length) {
      delete payload.permittedCarriers
    } else if (payload.permittedCarriers?.length && !payload.sources?.length) {
      payload.sources = ['1G']
    }

    const { adult, child, infant } = payload
    const unknownAirport = []
    let expiredAtToReloadRequire = null
    try {
      resetStore()
      await store.dispatch('userStore/fetchMeData')
      const { data: searchId } = await store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/searchFlight`, payload)

      // fetch available promotion
      const agency = getUserData().employeeData.agency
      const parentAgencyCode = agency.parentAgency?.agencyCode
      const masterAgencyCode = agency.parentAgency?.parentAgency?.agencyCode
      const promotionsArray = []
      const searchFlightArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()
      Promise.all(
        searchFlightArray.map(flight => {
          const params = {
            flightStartTime: flight.departDate,
            numberPassenger: adult + child,
            createdAgencies: [parentAgencyCode, masterAgencyCode].filter(item => !!item),
          }
          return store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchPromotionAvailable`, params)
        }),
      )
        .then(results => {
          results.forEach(res => { promotionsArray.push(res.data.items) })
        })

      let lastLengthResources = 0
      const filterSources = []
      const filterAirlines = []
      const filterStopPoints = []
      const filterTransitPoints = []
      const filterDurations = []
      const filterAirCrafts = []
      const filterPrices = []
      const filterDepartureTime = []
      // const filterArrivalTime = []

      let timeCounter = 0
      const promisesArr = []
      let domestic = null
      let completedJobs = 0
      let numberOfJobs = 0

      const intervalID = setInterval(async () => {
        try {
          const res = await store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/getFlightSearchOperation`, searchId)
          const { resources, numberOfJobs: numberOfJobsInProgress, status } = res.data
          numberOfJobs = numberOfJobsInProgress * searchFlightArray.length
          if (resources.length > lastLengthResources) {
            const arrayDiff = resources.slice(lastLengthResources, resources.length)
            arrayDiff.forEach(async resId => {
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoading`, true)
              promisesArr.push(
                store
                  .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/getResourceItinerary`, resId)
                  .then(resResourceID => {
                    const hideFee = store.getters['userStore/getHideFee']
                    // giá kết hợp
                    const isUsingGroupResponseData = resResourceID.data.isGroupedItineraryResponse && !['VN1A', 'QH', 'TR'].includes(resResourceID.data?.source)
                    if (isUsingGroupResponseData) {
                      const agencyFeeByTicketSources = computed(() => store.getters['globalConfig/getConfig']('agencyFeeByTicketSources'))
                      resResourceID.data.trips = resResourceID.data.trips.map(trip => {
                        const contentSource = trip?.contentSource
                        // tinh toan cac the loai phi
                        const promotion = {
                          promotionAdult: 0,
                          promotionChild: 0,
                          promotionInfant: 0,
                        }
                        trip.domestic = false
                        const serviceFee = store.getters['userStore/getServiceFee'](trip.domestic, trip.source)
                        const isSegmentFeeType = serviceFee?.feeType === 'FLIGHT_SEGMENT'

                        const agencyFee = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source)
                        const isSegmentAgencyFeeType = agencyFee?.feeType === 'FLIGHT_SEGMENT'
                        let totalFeeForBestFare = 0 // biên để cộng phí vào bestFare lưu vào filter giá
                        let numberSequence = 0
                        if (trip?.journeys && trip?.journeys.length) {
                          trip.journeys = trip.journeys.map(journey => {
                            // modify fareOption
                            const fareOptRaw = trip.fareOptions.find(fo => fo.journeyIds.includes(journey.journeyId))
                            const fareOpt = cloneDeep(fareOptRaw)
                            const journeyIdLength = fareOpt?.journeyIds.length
                            const indexJourneyId = fareOpt?.journeyIds.indexOf(journey.journeyId)
                            if (journeyIdLength > 1) {
                              fareOpt.bookingClass = fareOpt.bookingClass.split(' | ')[indexJourneyId]
                              fareOpt.cabin = fareOpt.cabin.split(' | ')[indexJourneyId]
                              fareOpt.fareBasisCode = fareOpt.fareBasisCode.split(' | ')[indexJourneyId]
                              fareOpt.fareType = fareOpt.fareType.split(' | ')[indexJourneyId]
                              fareOpt.groupClass = fareOpt.groupClass.split(' | ')[indexJourneyId]
                              fareOpt.journeyIds = [fareOpt.journeyIds[indexJourneyId]]
                              fareOpt.fareAdult /= journeyIdLength
                              fareOpt.fareChild /= journeyIdLength
                              fareOpt.fareInfant /= journeyIdLength
                              fareOpt.taxAdult /= journeyIdLength
                              fareOpt.taxChild /= journeyIdLength
                              fareOpt.taxInfant /= journeyIdLength
                              fareOpt.surchargeAdult /= journeyIdLength
                              fareOpt.surchargeChild /= journeyIdLength
                              fareOpt.surchargeInfant /= journeyIdLength
                              fareOpt.totalAdult /= journeyIdLength
                              fareOpt.totalChild /= journeyIdLength
                              fareOpt.totalInfant /= journeyIdLength
                            }

                            const numberSegments = journey.segments.length

                            // service fee
                            const amountSegmentToMultipleFeeService = isSegmentFeeType ? numberSegments : 1
                            const serviceFeeAdult = (serviceFee?.adultAmount * amountSegmentToMultipleFeeService) || 0
                            const serviceFeeChild = (serviceFee?.childAmount * amountSegmentToMultipleFeeService) || 0
                            const serviceFeeInfant = (serviceFee?.infantAmount * amountSegmentToMultipleFeeService) || 0

                            // agency fee
                            const amountSegmentToMultipleFeeAgency = isSegmentAgencyFeeType ? numberSegments : 1
                            const agencyFeeByTicket = agencyFeeByTicketSources.value.find(item => item.key === trip.source)

                            // NOTE: các source chỉ tính 1 lần phí xuất vé cho F2
                            const isAgencyFeeOnce = (agencyFeeByTicket.value?.contentSource ? agencyFeeByTicket.value?.contentSource === contentSource : !!agencyFeeByTicket.value) && isRoleF2.value
                            const agencyFeeAmount = (agencyFee?.amount || 0) * amountSegmentToMultipleFeeAgency

                            const feeForBestFare = serviceFeeAdult + (hideFee ? agencyFeeAmount : 0)
                            if (feeForBestFare > totalFeeForBestFare) {
                              totalFeeForBestFare = feeForBestFare
                            }
                            if (journey.sequence > numberSequence) {
                              numberSequence = journey.sequence
                            }

                            const fareItemModified = {
                              ...fareOpt,
                              hideFee,
                              serviceFeeAdult,
                              serviceFeeChild,
                              serviceFeeInfant,
                              agencyFee: agencyFeeAmount,
                              totalAgencyFee: agencyFeeAmount * (adult + child),
                              isAgencyFeeOnce,
                              totalFeeAdult: fareOpt.surchargeAdult + fareOpt.taxAdult + serviceFeeAdult + (hideFee ? agencyFeeAmount : 0),
                              totalFeeChild: fareOpt.surchargeChild + fareOpt.taxChild + serviceFeeChild + (hideFee ? agencyFeeAmount : 0),
                              totalFeeInfant: fareOpt.surchargeInfant + fareOpt.taxInfant + serviceFeeInfant, // INF ko + phi xuat ve
                              // số segment để nhân khi phí dịch vụ = FLIGHT_SEGMENT
                              amountSegmentToMultipleFeeService,
                              // Fare and discount, promotion
                              promotionAdult: promotion.promotionAdult,
                              promotionChild: promotion.promotionChild,
                              promotionInfant: promotion.promotionInfant,
                              fareAdultAfterDiscount: fareOpt.fareAdult - fareOpt.discountAdult,
                              fareChildAfterDiscount: fareOpt.fareChild - fareOpt.discountChild,
                              fareInfantAfterDiscount: fareOpt.fareInfant - fareOpt.discountInfant,
                              fareAdultAfterDiscountAndPromotion: fareOpt.fareAdult - fareOpt.discountAdult - promotion.promotionAdult,
                              fareChildAfterDiscountAndPromotion: fareOpt.fareChild - fareOpt.discountChild - promotion.promotionChild,
                              fareInfantAfterDiscountAndPromotion: fareOpt.fareInfant - fareOpt.discountInfant - promotion.promotionInfant,
                              // Total
                              totalAdultModified: fareOpt.fareAdult - fareOpt.discountAdult - promotion.promotionAdult + fareOpt.surchargeAdult + fareOpt.taxAdult + serviceFeeAdult + (hideFee ? agencyFeeAmount : 0),
                              totalChildModified: fareOpt.fareChild - fareOpt.discountChild - promotion.promotionChild + fareOpt.surchargeChild + fareOpt.taxChild + serviceFeeChild + (hideFee ? agencyFeeAmount : 0),
                              totalInfantModified: fareOpt.fareInfant - fareOpt.discountInfant - promotion.promotionInfant + fareOpt.surchargeInfant + fareOpt.taxInfant + serviceFeeInfant,
                            }

                            const afterMultiple = {
                              totalAdultModified: fareItemModified.totalAdultModified * adult,
                              totalChildModified: fareItemModified.totalChildModified * child,
                              totalInfantModified: fareItemModified.totalInfantModified * infant,
                              total: fareItemModified.totalAdultModified * adult
                                    + fareItemModified.totalChildModified * child
                                    + fareItemModified.totalInfantModified * infant,
                              totalBeforePromotion: (fareItemModified.totalAdultModified + fareItemModified.promotionAdult) * adult
                                  + (fareItemModified.totalChildModified + fareItemModified.promotionChild) * child
                                  + (fareItemModified.totalInfantModified + fareItemModified.promotionInfant) * infant,
                            }

                            const fareOptions = {
                              ...fareItemModified,
                              id: uuidv4(),
                              // promotionInfo: promotion.code ? promotion : undefined,
                              // promotionParent: promotionParent.code ? promotionParent : undefined,
                              passenger: { adult, child, infant },
                              afterMultiple,
                            }

                            journey.segments.forEach(segment => {
                              // find airport
                              const iataCodesToFind = [segment.departure.IATACode, segment.arrival.IATACode]
                              if (segment.flightLegInfoList?.length) {
                                segment.flightLegInfoList.forEach(seg => {
                                  iataCodesToFind.push(seg.departure.IATACode, seg.arrival.IATACode)
                                })
                              }
                              iataCodesToFind.forEach(item => {
                                if (!getAirportByAirportCode(item) && !unknownAirport.includes(item)) {
                                  unknownAirport.push(item)
                                  getAirportsByCodeList([item])
                                }
                              })

                              // Add airline filter
                              const segmentAirline = segment.airline
                              const findAirlineInFiltersIndex = filterAirlines.findIndex(item => item.value === segmentAirline)
                              if (fareOptions) {
                                if (findAirlineInFiltersIndex === -1) {
                                  filterAirlines.push({
                                    value: segmentAirline,
                                    text: store.getters['globalConfig/getAirlineNameByCode'](segmentAirline),
                                    minPrice: fareOptions.fareAdult,
                                  })
                                } else {
                                  const filterAirlineFound = filterAirlines[findAirlineInFiltersIndex]
                                  if (filterAirlineFound.minPrice > fareOptions.fareAdult) {
                                    filterAirlineFound.minPrice = fareOptions.fareAdult
                                  }
                                }
                              }

                              // add filter duration
                              if (segment?.duration && !filterDurations.includes(segment.duration)) {
                                filterDurations.push(segment.duration)
                              }

                              const parsedTime = moment.utc(segment.departure.at).utcOffset(segment.departure.timezone)
                              if (!filterDepartureTime.includes(parsedTime.hours() * 60 + parsedTime.minutes())) {
                                filterDepartureTime.push(parsedTime.hours() * 60 + parsedTime.minutes())
                              }

                              const airCraft = segment.airCraft
                              filterAirCrafts.push({
                                text: airCraft ? `${store.getters['globalConfig/getAirCraftByIata'](airCraft)} (${airCraft})` || airCraft : 'Unknown',
                                value: airCraft,
                              })
                            })

                            // add numberStop
                            const numberStop = journey.segments.length - 1
                            if (!filterStopPoints.find(item => item.value === numberStop)) {
                              if (numberStop === 0) {
                                filterStopPoints.push({ text: 'Direct', value: 0 })
                              } else if (numberStop > 0) {
                                filterStopPoints.push({
                                  text: `${numberStop} transit${numberStop > 1 ? 's' : ''}`,
                                  value: numberStop,
                                })
                              }
                            }

                            // add transit points
                            if (numberStop > 0) {
                              const transitPointsArray = journey.stopPoint.split(' | ')

                              const transitPointsToPush = transitPointsArray.map(
                                item => {
                                  const getAirportOfItem = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](item)
                                  return {
                                    text: getAirportOfItem?.city || getAirportOfItem?.name || item,
                                    value: item,
                                  }
                                },
                              )
                              transitPointsToPush.forEach(item => filterTransitPoints.push(item))
                            }

                            return {
                              ...journey,
                              seatAvailable: fareOptions.seatAvailable,
                              segments: journey.segments.map((segment, segmentIndex) => ({
                                ...segment,
                                bookingClass: journey.bookingClass.split(' | ')[segmentIndex],
                                groupClass: journey.groupClass.split(' | ')[segmentIndex],
                                fareBasisCode: journey.fareBasisCode.split(' | ')[segmentIndex],
                                fareType: journey.fareType.split(' | ')[segmentIndex],
                              })),
                              id: uuidv4(),
                              domestic: false,
                              source: trip.source,
                              fareOptions,
                            }
                          })
                            .sort((a, b) => a.sequence - b.sequence)
                        }

                        const tripSource = trip.source
                        if (!filterSources.find(item => item.value === tripSource)) {
                          filterSources.push({
                            value: tripSource,
                            text: tripSource,
                          })
                        }

                        filterPrices.push((trip.bestCombinationFare.totalAdult + (totalFeeForBestFare * numberSequence)) * adult) // FIXME tạm thời - fix sau

                        return ({
                          ...trip,
                          id: uuidv4(),
                        })
                      })

                      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/pushResultCombinationSearchFlight`, resResourceID.data.trips)
                    }
                    // giá ow
                    else {
                      if (resResourceID.data.trips.length) {
                        resResourceID.data.trips.forEach(trip => {
                          trip.isGroupedItineraryResponse = resResourceID.data.isGroupedItineraryResponse
                          // eslint-disable-next-line no-unused-expressions
                          trip.segments?.forEach(segment => {
                            const iataCodesToFind = [segment.departure.IATACode, segment.arrival.IATACode]
                            if (segment.flightLegInfoList?.length) {
                              segment.flightLegInfoList.forEach(seg => {
                                iataCodesToFind.push(seg.departure.IATACode, seg.arrival.IATACode)
                              })
                            }
                            iataCodesToFind.forEach(item => {
                              if (!getAirportByAirportCode(item) && !unknownAirport.includes(item)) {
                                unknownAirport.push(item)
                                getAirportsByCodeList([item])
                              }
                            })
                          })

                          // add source filter
                          const tripSource = trip.source
                          if (!filterSources.find(item => item.value === tripSource)) {
                            filterSources.push({
                              value: tripSource,
                              text: tripSource,
                            })
                          }

                          const tripAirline = trip.segments?.length && trip.segments[0]?.operating === 'BL' ? 'BL' : trip.airline
                          const findAirlineInFiltersIndex = filterAirlines.findIndex(item => item.value === tripAirline)
                          if (trip.fareOptions.length) {
                            if (findAirlineInFiltersIndex === -1) {
                              filterAirlines.push({
                                value: tripAirline,
                                text: store.getters['globalConfig/getAirlineNameByCode'](tripAirline.split(' | ')[0]),
                                minPrice: trip.fareOptions[0].fareAdult,
                              })
                            } else {
                              const filterAirlineFound = filterAirlines[findAirlineInFiltersIndex]
                              if (filterAirlineFound.minPrice > trip.fareOptions[0].fareAdult) {
                                filterAirlineFound.minPrice = trip.fareOptions[0].fareAdult
                              }
                            }
                          }
                        })
                      }
                      const availablePromotions = promotionsArray[resResourceID.data.itineraryId - 1] ?? []

                      resResourceID.data.trips.forEach(trip => {
                        const serviceFee = store.getters['userStore/getServiceFee'](trip.domestic, trip.source)
                        // Nhân phí dịch vụ với số chặng nếu phí dịch vụ tính theo số điểm dừng
                        const isSegmentFeeType = serviceFee?.feeType === 'FLIGHT_SEGMENT'
                        const amountSegmentToMultipleFeeService = isSegmentFeeType ? trip.segments.length : 1
                        const serviceFeeAdult = (serviceFee?.adultAmount * amountSegmentToMultipleFeeService) || 0
                        const serviceFeeChild = (serviceFee?.childAmount * amountSegmentToMultipleFeeService) || 0
                        const serviceFeeInfant = (serviceFee?.infantAmount * amountSegmentToMultipleFeeService) || 0
                        const agencyFee = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source)
                        // const agencyFeeAmount = agencyFee?.amount || 0
                        const isSegmentAgencyFeeType = agencyFee?.feeType === 'FLIGHT_SEGMENT'
                        const amountSegmentToMultipleFeeAgency = isSegmentAgencyFeeType ? trip.segments.length : 1
                        const agencyFeeAmount = (agencyFee?.amount || 0) * amountSegmentToMultipleFeeAgency
                        if (domestic === null) {
                          domestic = trip.domestic
                        }
                        const getFilterSkyboss = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterSkyboss`]
                        trip.fareOptions = trip.fareOptions.map(fareItem => {
                          // fareItem.discountAdult = 10000 // set discount for development

                          if (fareItem?.expiredAt && (!expiredAtToReloadRequire || fareItem?.expiredAt < expiredAtToReloadRequire)) {
                            expiredAtToReloadRequire = fareItem?.expiredAt
                          }
                          const matchedPromotions = availablePromotions.filter(
                            promotion => {
                              const isSource = !promotion.source?.length || promotion.source.includes(trip.source)
                              const isAirline = !promotion.airline?.length || promotion.airline.includes(trip.airline)
                              const isOperator = !promotion.operator?.length || promotion.operator.includes(trip.operator)

                              const isBookingClass = !promotion.bookingClass?.length || promotion.bookingClass.includes(fareItem.bookingClass)
                              const isGroupClass = !promotion.groupClass?.length || promotion.groupClass.includes(fareItem.groupClass)
                              const isFareBasis = !promotion.fareBasisCode?.length || promotion.fareBasisCode.includes(fareItem.fareBasisCode)

                              const flightType = trip.domestic ? 'INLAND' : 'INTERNATIONAL'
                              const isFlightType = !promotion.flightType || promotion.flightType === flightType
                              const flightDesignator = trip.operator + trip.flightNumber // VN203
                              const isFlightDesignator = !promotion.flightDesignators?.length || promotion.flightDesignators.includes(flightDesignator)

                              // check flight
                              const isFlightArrival = !promotion.flightArrivals?.length || promotion.flightArrivals.some(e => comparePoint(e, trip.arrival.IATACode))
                              const isFlightDeparture = !promotion.flightDepartures?.length || promotion.flightDepartures.some(e => comparePoint(e, trip.departure.IATACode))
                              const flightTrips = mixFlightTrips(trip.departure, trip.arrival)
                              const isFlightTrip = !promotion.flightTrips?.length || promotion.flightTrips.some(configTrip => flightTrips.some(flightTrip => compareTrip(flightTrip, configTrip)))

                              // check country
                              const isCountryArrival = !promotion.countryArrivals?.length || promotion.countryArrivals.some(e => comparePoint(e, trip.arrival.country))
                              const isCountryDeparture = !promotion.countryDepartures?.length || promotion.countryDepartures.some(e => comparePoint(e, trip.departure.country))
                              const countryTrips = mixCountryTrips(trip.departure, trip.arrival)
                              const isCountryTrip = !promotion.countryTrips?.length || promotion.countryTrips.some(configTrip => countryTrips.some(countryTrip => compareTrip(countryTrip, configTrip)))

                              // check region
                              const isRegionArrival = !promotion.regionArrivals?.length || promotion.regionArrivals.some(e => comparePoint(e, trip.arrival.region))
                              const isRegionDeparture = !promotion.regionDepartures?.length || promotion.regionDepartures.some(e => comparePoint(e, trip.departure.region))
                              const regionTrips = mixRegionTrips(trip.departure, trip.arrival)
                              const isRegionTrip = !promotion.regionTrips?.length || promotion.regionTrips.some(configTrip => regionTrips.some(regionTrip => compareTrip(regionTrip, configTrip)))

                              const rs = isCountryArrival && isCountryDeparture && isCountryTrip
                                && isRegionArrival && isRegionDeparture && isRegionTrip
                                && isFlightArrival && isFlightDeparture && isFlightTrip
                                && isSource && isAirline && isOperator
                                && isBookingClass && isGroupClass && isFareBasis
                                && isFlightType
                                && isFlightDesignator
                              return rs
                            },
                          )

                          const sortedPromotions = matchedPromotions.sort((a, b) => {
                            const discountA = a.discountAmount + (a.discountPercent / 100) * fareItem.fareAdult
                            const discountB = b.discountAmount + (b.discountPercent / 100) * fareItem.fareAdult
                            return discountB - discountA
                          })

                          let promotion = sortedPromotions.filter(promotion => promotion.createdAgency === parentAgencyCode
                            && (!promotion.agencies?.length || promotion.agencies.includes(agency.agencyCode)))[0]
                          let promotionParent = sortedPromotions.filter(promotion => promotion.createdAgency === masterAgencyCode
                            && (!promotion.agencies?.length || promotion.agencies.includes(parentAgencyCode)))[0]

                          promotion = calculatePromotion(promotion, fareItem, adult, child)
                          promotionParent = calculatePromotion(promotionParent, fareItem, adult, child)

                          // ANCHOR: Add fee to fareItem
                          const fareItemModified = {
                            ...fareItem,
                            // Fee
                            hideFee,
                            serviceFeeAdult,
                            serviceFeeChild,
                            serviceFeeInfant,
                            agencyFee: agencyFeeAmount,
                            totalAgencyFee: agencyFeeAmount * (adult + child),
                            totalFeeAdult: fareItem.surchargeAdult + fareItem.taxAdult + serviceFeeAdult + (hideFee ? agencyFeeAmount : 0),
                            totalFeeChild: fareItem.surchargeChild + fareItem.taxChild + serviceFeeChild + (hideFee ? agencyFeeAmount : 0),
                            totalFeeInfant: fareItem.surchargeInfant + fareItem.taxInfant + serviceFeeInfant, // INF ko + phi xuat ve
                            // số segment để nhân khi phí dịch vụ = FLIGHT_SEGMENT
                            amountSegmentToMultipleFeeService,
                            // Fare and discount, promotion
                            promotionAdult: promotion.promotionAdult,
                            promotionChild: promotion.promotionChild,
                            promotionInfant: promotion.promotionInfant,
                            fareAdultAfterDiscount: fareItem.fareAdult, // - fareItem.discountAdult,
                            fareChildAfterDiscount: fareItem.fareChild, // - fareItem.discountChild,
                            fareInfantAfterDiscount: fareItem.fareInfant, // - fareItem.discountInfant,
                            fareAdultAfterDiscountAndPromotion: fareItem.fareAdult - fareItem.discountAdult - promotion.promotionAdult,
                            fareChildAfterDiscountAndPromotion: fareItem.fareChild - fareItem.discountChild - promotion.promotionChild,
                            fareInfantAfterDiscountAndPromotion: fareItem.fareInfant - fareItem.discountInfant - promotion.promotionInfant,
                            // Total
                            totalAdultModified: fareItem.fareAdult - fareItem.discountAdult - promotion.promotionAdult + fareItem.surchargeAdult + fareItem.taxAdult + serviceFeeAdult + (hideFee ? agencyFeeAmount : 0),
                            totalChildModified: fareItem.fareChild - fareItem.discountChild - promotion.promotionChild + fareItem.surchargeChild + fareItem.taxChild + serviceFeeChild + (hideFee ? agencyFeeAmount : 0),
                            totalInfantModified: fareItem.fareInfant - fareItem.discountInfant - promotion.promotionInfant + fareItem.surchargeInfant + fareItem.taxInfant + serviceFeeInfant,
                          }

                          const afterMultiple = {
                            totalAdultModified: fareItemModified.totalAdultModified * adult,
                            totalChildModified: fareItemModified.totalChildModified * child,
                            totalInfantModified: fareItemModified.totalInfantModified * infant,
                            total: fareItemModified.totalAdultModified * adult
                            + fareItemModified.totalChildModified * child
                            + fareItemModified.totalInfantModified * infant,
                            totalBeforePromotion: (fareItemModified.totalAdultModified + fareItemModified.promotionAdult) * adult
                          + (fareItemModified.totalChildModified + fareItemModified.promotionChild) * child
                          + (fareItemModified.totalInfantModified + fareItemModified.promotionInfant) * infant,
                          }
                          const result = {
                            ...fareItemModified,
                            id: uuidv4(),
                            promotionInfo: promotion.code ? promotion : undefined,
                            promotionParent: promotionParent.code ? promotionParent : undefined,
                            passenger: { adult, child, infant },
                            afterMultiple,
                          }
                          filterPrices.push(result.afterMultiple.totalAdultModified)
                          if (!getFilterSkyboss && SKYBOSS_FILTER_REGEX.test(fareItem.fareType)) {
                            store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterSkyboss`, true)
                          }
                          return result
                        })

                        if (trip?.duration && !filterDurations.includes(trip.duration)) {
                          filterDurations.push(trip.duration)
                        }

                        if (trip?.departure?.at && trip?.departure?.timezone) {
                          const parsedTime = moment.utc(trip?.departure?.at).utcOffset(trip?.departure?.timezone)
                          if (!filterDepartureTime.includes(parsedTime.hours() * 60 + parsedTime.minutes())) {
                            filterDepartureTime.push(parsedTime.hours() * 60 + parsedTime.minutes())
                          }
                        }

                        const airCrafts = (trip?.segments ?? []).map(s => s?.airCraft).filter(Boolean)
                        if (!isEmpty(airCrafts)) {
                          airCrafts.forEach(aircraft => filterAirCrafts.push({
                            text: aircraft ? `${store.getters['globalConfig/getAirCraftByIata'](aircraft)} (${aircraft})` || aircraft : 'Unknown',
                            value: aircraft,
                          }))
                        }

                        const numberStop = trip.legs - 1
                        if (!filterStopPoints.find(item => item.value === numberStop)) {
                          if (numberStop === 0) {
                            filterStopPoints.push({ text: 'Direct', value: 0 })
                          } else if (numberStop > 0) {
                            filterStopPoints.push({
                              text: `${numberStop} transit${numberStop > 1 ? 's' : ''}`,
                              value: numberStop,
                            })
                          }
                        }

                        if (numberStop > 0) {
                          const transitPointsArray = trip.stopPoint.split(' | ')

                          const transitPointsToPush = transitPointsArray.map(
                            item => {
                              const getAirportOfItem = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](item)
                              return {
                                text: getAirportOfItem?.city || getAirportOfItem?.name || item,
                                value: item,
                              }
                            },
                          )
                          transitPointsToPush.forEach(item => filterTransitPoints.push(item))
                        }
                      })
                      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/pushResultSearchFlight`, resResourceID.data)
                    }
                  })
                  .catch(error => {
                    console.error(error)
                  })
                  .finally(() => {
                    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoading`, false)
                    completedJobs += 1
                    const percentJobs = numberOfJobs ? (completedJobs / numberOfJobs) * 100 : null
                    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setProgressValue`, percentJobs)

                    if (percentJobs === 100) {
                      setTimeout(() => {
                        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setProgressValue`, null)
                      }, 100)
                    }
                  }),
              )
            })
          }
          lastLengthResources = resources.length
          if (status === 'Succeeded') {
            numberOfJobs = resources.length
            const percentJobs = numberOfJobs ? (completedJobs / numberOfJobs) * 100 : null

            store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setProgressValue`, percentJobs)
          }
          timeCounter += 1
          if (status === 'Succeeded' || timeCounter >= TIME_LIMIT_REQUEST_SEARCH_ID) {
            Promise.all(promisesArr).then(() => {
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setIsDomestic`, domestic)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setStatusJobDone`, true)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setExpiredAtToReloadRequire`, expiredAtToReloadRequire)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterSources`, filterSources)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterAirlines`, filterAirlines)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterPrices`, filterPrices.length ? [Math.min(...filterPrices), Math.max(...filterPrices)] : [0, 0])
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterDepartureTime`, [Math.min(...filterDepartureTime), Math.max(...filterDepartureTime)])
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterStopPoints`, filterStopPoints)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterTransitPoints`, uniqBy(filterTransitPoints, 'value'))
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterDurations`, [Math.max(...filterDurations), Math.min(...filterDurations)])
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterAirCrafts`, uniqBy(filterAirCrafts, 'value'))
            })
            // Show toast error timeout search
            // if (timeCounter >= TIME_LIMIT_REQUEST_SEARCH_ID) {
            //   toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Lỗi',
            //       icon: 'AlertTriangleIcon',
            //       variant: 'danger',
            //       text: 'Quá thời gian tìm kiếm chuyến bay',
            //     },
            //   })
            // }
            clearIntervalIDs()
          }
        } catch (e) {
          clearIntervalIDs()
        }
      }, 1000)
      intervalIDs.push(intervalID)
    } catch (error) {
      console.error({ error })
    }
  }

  // ANCHOR Airport
  const fetchAirportGroup = () => new Promise((resolve, reject) => {
    store
      .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchAirportGroup`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  const getAirportGroup = computed(
    () => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`],
  )

  const fetchAirports = payload => new Promise((resolve, reject) => store
    .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchAirports`, {
      searchText: payload,
    })
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      toastError({
        title: 'messagesList.error',
        content: 'Lỗi tải dữ liệu sân bay',
      })
      reject(error)
    }))

  // ANCHOR Result
  const fetchCheapestFare = payload => new Promise((resolve, reject) => {
    store
      .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchCheapestFare`, payload)
      .then(res => {
        res.data.forEach(date => {
          date.cheapestFare = sortBy(date.cheapestFare, [item => item.netFare])
        })
        resolve(res)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi tải dữ liệu giá rẻ',
        })
        reject(error)
      })
  })

  // tripInfo: {isDomestic, legsTrip, segmentIndex, source}
  function getBookingClassAvail(bookingClassAvail, tripInfo) {
    const result = []
    let firstPriceShow = false
    // FIXME : Chỉ cộng phí hideFee cho F3 view
    const hideFee = store.getters['userStore/getHideFee']
    const agencyFee = store.getters['userStore/getAgencyFee'](tripInfo.isDomestic, tripInfo.source)
    const agencyFeeAmount = agencyFee?.amount || 0

    const d = groupBy(bookingClassAvail, item => item.code)

    for (const key in d) {
      if (Object.hasOwnProperty.call(d, key)) {
        const ele = d[key]
        const fareObj = {
          ...ele[0],
          uniqueBookingClassId: uuidv4(),
          source: tripInfo.source,
          airline: tripInfo.airline,
          code: key,
          availability: ele[0].availability,
          refundable: ele[0]?.refundable || false,
          hideFee,
          agencyFee,
          agencyFeeAmount,

          prices: ele.map(item => {
            const iGetFee = ((tripInfo.legsTrip > 1) && (tripInfo.segmentIndex === 0)) || (tripInfo.legsTrip === 1)
            return {
              paxType: ['ADT'].includes(item.paxType) ? 'ADULT' : ['CNN'].includes(item.paxType) ? 'CHILD' : ['INF'].includes(item.paxType) ? 'INFANT' : item.paxType,
              fareBasisCode: item?.fareBasisCode || '',
              net: item?.net || 0,
              tax: iGetFee ? ((item?.tax || 0) + (hideFee ? agencyFeeAmount : 0)) : item?.tax || 0, // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
              final_tax: iGetFee ? ((item?.tax || 0) + (hideFee ? agencyFeeAmount : 0)) : item?.tax || 0, // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
              fare: iGetFee ? ((item?.fare || 0) + (hideFee ? agencyFeeAmount : 0)) : item?.fare || 0, // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
              segmentValue: item?.segmentValue || '',
            }
          }).sort((a, b) => {
            if (a.paxType === 'ADULT') {
              return -1 // ADULT 1
            } if (a.paxType === 'CHILD' && b.paxType === 'INFANT') {
              return -1 // CHILD 2
            } if (a.paxType === 'CHILD' && b.paxType !== 'INFANT') {
              return -1 // INFANT 3
            } if (a.paxType === 'INFANT' && b.paxType !== 'CHILD') {
              return 1
            }
            return 1
          }),
        }

        if (!firstPriceShow) {
          if (fareObj.availability > 0) {
            fareObj.firstPriceShow = true
            firstPriceShow = true
          }
        }

        result.push(fareObj)
      }
    }

    return result
  }

  function addIndexSegmentToData(data, payload, tripData) {
    const isDomesticArray = []
    if (payload) {
      const flights = cloneDeep(payload.flights)
      if (payload.type === 'RT') {
        flights.push(payload.flights[0]) // NOTE: Thêm chặng với case RoundTrip
      }
      flights.forEach(({ startPoint, endPoint }) => {
        isDomesticArray.push(isDomesticFlight(startPoint, endPoint))
      })
    }

    const filterDepartureTime = []
    const filterAirCrafts = []
    const filterAirlines = []

    const result = data.map((item, itineraryIndex) => {
      const isDomestic = isDomesticArray ? isDomesticArray[itineraryIndex] : null
      const updatedTrips = item.trips.map(trip => {
        let minFareADT = null
        let minDepartureDate = null
        let minDuration = null
        let firstAirline = null
        let tripDeparture = null

        const tripIndex = uuidv4()
        const airCrafts = trip.segments.map(s => s.airCraft)

        if (!isEmpty(airCrafts)) {
          airCrafts.forEach(aircraft => filterAirCrafts.push({
            text: aircraft ? `${store.getters['globalConfig/getAirCraftByIata'](aircraft)} (${aircraft})` || aircraft : 'Unknown',
            value: aircraft,
          }))
        }

        const updatedSegments = trip.segments.map((segment, segmentIndex) => {
          const legsTrip = trip.segments.length
          const tripInfo = {
            source: tripData.airline ?? segment?.airline,
            airline: segment?.airline || '',
            segmentIndex: tripData?.segmentIndex || segmentIndex,
            legsTrip: tripData?.legsTrip || legsTrip,
            isDomestic,
          }

          const bookingClassAvail = getBookingClassAvail(segment.bookingClassAvail, tripInfo)

          // Add airline filter
          const segmentAirline = segment.airline
          const findAirlineInFiltersIndex = filterAirlines.findIndex(item => item.value === segmentAirline)
          if (findAirlineInFiltersIndex === -1) {
            filterAirlines.push({
              value: segmentAirline,
              text: store.getters['globalConfig/getAirlineNameByCode'](segmentAirline),
            })
          }

          if (!minDuration && (segmentIndex === 0)) {
            const getTimestampFromDateTimeAndTimezone = (date, timezone) => {
              const momentTime = moment(date).utcOffset(timezone)
              return momentTime.valueOf()
            }
            minDuration = trip.segments.reduce((acc, it) => acc + (getTimestampFromDateTimeAndTimezone(it.arrivalDate, it.arrivalTimezone) - getTimestampFromDateTimeAndTimezone(it.departureDate, it.departureTimezone)), 0)
          }

          if (!minFareADT && (segmentIndex === 0)) {
            minFareADT = bookingClassAvail.find(c => c.firstPriceShow)?.prices?.find(pax => pax.paxType === 'ADULT')?.fare || null
            minDepartureDate = new Date(segment.departureDate).getTime()
            const parsedTime = moment.utc(segment.departureDate).utcOffset(segment.departureTimezone)
            tripDeparture = parsedTime
            if (tripData.handleSearch && !filterDepartureTime.includes(parsedTime.hours() * 60 + parsedTime.minutes())) {
              filterDepartureTime.push(parsedTime.hours() * 60 + parsedTime.minutes())
            }
          }

          if (!firstAirline) {
            firstAirline = segment?.airline || null
          }

          return {
            ...segment,
            airline: ['VU'].includes(tripData.airline) ? tripData.airline : segment.airline,
            isDomestic,
            uniqueSegmentId: uuidv4(),
            legsTrip,
            leg: segmentIndex,
            tripIndex,
            segmentValue: !isEmpty(segment.bookingClassAvail) ? segment.bookingClassAvail[0]?.segmentValue : null,
            itineraryIndex,
            bookingClassAvail,
          }
        })

        return {
          ...trip,
          minFareADT,
          minDepartureDate,
          minDuration,
          firstAirline,
          tripDeparture,
          segments: updatedSegments,
        }
      })
      return {
        // Thêm field isDomestic vào trong result[index]
        isDomestic,
        nextResultReference: item?.nextResultReference ?? null,
        itineraryIndex,
        trips: updatedTrips,
      }
    })

    if (tripData.handleSearch) {
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterDepartureTimeClassBooking`, [min(filterDepartureTime), max(filterDepartureTime)])
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterAirCrafts`, uniqBy(filterAirCrafts, 'value'))
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setFilterAirlines`, filterAirlines)
    }

    return result
  }

  const searchClassBooking = (payload, type = 'all') => new Promise((resolve, reject) => {
    if (!payload.airline) {
      payload.airline = payload.airlines[0]
    }
    if (isArray(payload.airline)) {
      payload.airline = payload.airline[0]
    }
    if (payload.type === 'RT' && payload.flights.length > 1) {
      payload.flights = payload.flights.slice(0, 1)
    }

    if (['all'].includes(type)) { // not refresh case
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSearchFlight`, payload)
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAirlineSearchClassBooking`, payload.airline)
      localStorage.setItem('searchFlight', JSON.stringify(payload))
    }

    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoading`, true)

    store
      .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/searchClassBooking`, payload)
      .then(res => {
        const result = addIndexSegmentToData(res, payload, { airline: payload.airline, handleSearch: true })

        if (res?.[0]?.nextResultReference) {
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setNextResultReference`, res?.[0]?.nextResultReference)
        }

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDefaultDataAndResultSearchClassBooking`, result)
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSelectedTripClassBooking`, [])
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddMoreFlightClassBooking`, [])
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDataCreatedBookingClassBooking`, null)
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoading`, false)
      })
  })

  const searchMoreResultClassBooking = () => new Promise((resolve, reject) => {
    const getSearchPayload = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`]
    const nextResultReference = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getNextResultReference`]

    const payload = {
      ...getSearchPayload,
      nextResultReference,
    }

    // store.dispatch('app/setLoading', true)

    store
      .dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/searchMoreResultClassBooking`, payload)
      .then(res => {
        const oldResult = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getResultSearchClassBooking`]
        const newResult = addIndexSegmentToData(res, payload, { airline: payload.airline, handleSearch: true })

        const result = newResult.map((itinerary, index) => {
          if (index === 0) {
            if (itinerary.nextResultReference) {
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setNextResultReference`, itinerary.nextResultReference)
            } else {
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setNextResultReference`, null)
            }
          }
          const oldTripByResult = oldResult.find(it => it.itineraryIndex === index)
          const data = {
            ...itinerary,
            trips: oldTripByResult ? itinerary.trips.concat(oldTripByResult.trips) : itinerary.trips,
          }
          return data
        })

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/updateResultDataSearchClassBooking`, result)

        // store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDefaultDataAndResultSearchClassBooking`, result)
        // store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSelectedTripClassBooking`, [])
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
      // .finally(() => {
      // store.dispatch('app/setLoading', false)
      // })
  })

  const getClassBookingInfoTrip = (payload, searchPayload, tripData) => new Promise((resolve, reject) => {
    apiBooking.getClassBookingInfoTrip(payload)
      .then(response => {
        const result = addIndexSegmentToData(response, searchPayload, tripData)
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  })

  const calculatePriceClassBooking = payload => new Promise((resolve, reject) => {
    const fSegment = payload.itineraries[0].segments[0] // trip 0
    const legTrip = payload.itineraries.length
    const isDomestic = isDomesticFlight(fSegment.departure, fSegment.departure)

    const hideFee = store.getters['userStore/getHideFee']
    const agencyFee = store.getters['userStore/getAgencyFee'](isDomestic, '1S')
    const agencyFeeAmount = agencyFee?.amount || 0

    // const serviceFee = store.getters['userStore/getServiceFee'](isDomestic, '1S')
    // const serviceFeeAdult = serviceFee?.adultAmount || 0
    // const serviceFeeChild = serviceFee?.childAmount || 0
    // const serviceFeeInfant = serviceFee?.infantAmount || 0

    store
      .dispatch('app-flight-v2/calculatePrice', payload)
      .then(res => {
        const data = res.map(paxPrices => {
          // const serviceFeePax = ['ADULT'].includes(paxPrices.fareInfos[0].paxType) ? serviceFeeAdult : ['CHILD'].includes(paxPrices.fareInfos[0].paxType) ? serviceFeeChild : serviceFeeInfant

          const quantityPax = payload.passengerTypeRequests.find(p => p.code === paxPrices.fareInfos[0].paxType)?.quantity || 1
          const totalTaxes = paxPrices.totalTaxes + (hideFee ? agencyFeeAmount * legTrip : 0)
          const totalPrice = paxPrices.totalPrice + (hideFee ? agencyFeeAmount * legTrip : 0)
          const finalTotalTaxes = paxPrices.totalTaxes + (hideFee ? agencyFeeAmount * legTrip : 0) // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
          const finalTotalPrices = paxPrices.totalPrice + (hideFee ? agencyFeeAmount * legTrip : 0) // NOTE: hiện tại chưa thêm gì ngoài phí ẩn

          return {
            ...paxPrices,
            totalTaxes,
            totalPrice,
            quantityPax,
            // serviceFeePax,
            finalTotalTaxes,
            finalTotalPrices,

          }
        })

        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })

  const statusJobDone = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusJobDone`])
  const tabIndex = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getTabIndex`])
  const isDomestic = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getIsDomestic`])
  const airlineRoundtrip = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirlineRoundtrip`])
  const combinationKeys = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getCombinationKeys`])

  // ANCHOR Generals
  const setDefaultDataPaxSeat = segments => {
    store.dispatch(
      `${FLIGHT_APP_STORE_MODULE_NAME}/setDefaultDataPaxSeat`,
      segments,
    )
  }

  const getLoading = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getLoading`])
  const processValue = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getProgressValue`])

  //  ANCHOR create booking
  const createBooking = bookingData => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-v2/createBooking', bookingData)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
  })

  const commandCreateBooking = bookingData => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-v2/commandCreateBooking', bookingData)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
  })

  const draftBooking = bookingData => apiBooking.draftBooking(bookingData)

  //  ANCHOR create group booking
  const createGroupBooking = bookingData => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-v2/createGroupBooking', bookingData)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
  })

  const createItineraries = (flightData, isCombination) => {
    const itineraries = []
    const pushTrip = flightData.find(trip => ['VN1A'].includes(trip.source))

    if (pushTrip) {
      // has VN1A trip(s)
      const pushSegment = flightData.filter(trip => !['VN1A'].includes(trip.source)) // remaining trip
      const grBySource = groupBy(flightData, 'source')

      if (!isEmpty(pushSegment)) {
        if (grBySource.VN1A) {
          groupBy(flightData, 'source').VN1A.forEach(trip => {
            itineraries.push({
              airline: 'VN',
              source: 'VN1A',
              departure: trip.departure.IATACode,
              arrival: trip.arrival.IATACode,
              departureDate: convertISODateTime(
                trip.departure.at,
                trip.departure.timezone,
              ).ISOdatetime,
              arrivalDate: convertISODateTime(
                trip.arrival.at,
                trip.arrival.timezone,
              ).ISOdatetime,
              flightNumber: trip.flightNumber,
              operating: '',
              fareBasisCode: trip.fareOptions.fareBasisCode,
              bookingClass: trip.bookingClass,
              groupClass: trip.groupClass,
              fareValue: trip.fareOptions.fareValue,
              itineraryId: trip?.airlineItineraryId || '',
            })
          })
        }

        pushSegment.forEach(remTrip => {
          remTrip.segments.forEach(segment => {
            itineraries.push({
              airline: segment.airline,
              source: remTrip.source,
              departure: segment.departure.IATACode,
              arrival: segment.arrival.IATACode,
              departureDate: convertISODateTime(
                segment.departure.at,
                segment.departure.timezone,
              ).ISOdatetime,
              arrivalDate: convertISODateTime(
                segment.arrival.at,
                segment.arrival.timezone,
              ).ISOdatetime,
              flightNumber: segment.flightNumber,
              flightNOP: segment.flightNumber,
              fareBasisCode: remTrip.fareOptions.fareBasisCode,
              bookingClass: remTrip.fareOptions.bookingClass,
              groupClass: remTrip.fareOptions.groupClass,
              segmentId: ['QH'].includes(remTrip.source) ? segment.airlineSegmentId : segment.segmentId,
              itineraryId: ['VJ', '1A'].includes(remTrip.source) ? remTrip?.airlineItineraryId : segment.segmentValue,
              fareValue: remTrip.fareOptions.fareValue,
            })
          })
        })
      } else {
        // eslint-disable-next-line no-lonely-if
        if (grBySource.VN1A) {
          groupBy(flightData, 'source').VN1A.forEach((trip, tripIndex) => {
            itineraries.push({ // TH: VN1A GHK (combine)
              airline: 'VN',
              source: 'VN1A',
              departure: trip.departure.IATACode,
              arrival: trip.arrival.IATACode,
              departureDate: convertISODateTime(trip.departure.at, trip.departure.timezone).ISOdatetime,
              arrivalDate: convertISODateTime(trip.arrival.at, trip.arrival.timezone).ISOdatetime,
              flightNumber: trip?.flightNumber || trip?.segments?.map(s => s.flightNumber)?.join(' | ') || '',
              operating: '',
              fareBasisCode: trip.fareOptions?.fareBasisCode || trip?.fareBasisCode || '',
              bookingClass: trip.bookingClass,
              groupClass: trip.groupClass,
              fareValue: trip.fareOptions?.fareValue,
              itineraryId: trip?.airlineItineraryId || String(tripIndex + 1) || '',
            })
          })
        }
      }
    } else {
      // No VN1A trip
      // eslint-disable-next-line no-unused-vars
      flightData.map((trip, tripIndex) => trip.segments.map((segment, segmentIndex) => itineraries.push({
        airline: segment.airline,
        source: trip.source,
        departure: segment.departure.IATACode,
        arrival: segment.arrival.IATACode,
        departureDate: convertISODateTime(
          segment.departure.at,
          segment.departure.timezone,
        ).ISOdatetime,
        arrivalDate: convertISODateTime(
          segment.arrival.at,
          segment.arrival.timezone,
        ).ISOdatetime,
        flightNumber: segment.flightNumber,
        flightNOP: segment.flightNumber?.trim() || '',
        fareBasisCode: trip.fareOptions?.fareBasisCode || '',
        bookingClass: trip.fareOptions.bookingClass || '',
        groupClass: trip.fareOptions.groupClass || '',
        segmentId: ['QH'].includes(trip.source) ? segment.airlineSegmentId : segment.segmentId,
        fareValue: trip.fareOptions?.fareValue || null,
        itineraryId: isCombination
          ? String(tripIndex + 1)
          : ['VJ', '1A'].includes(trip.source)
            ? trip?.airlineItineraryId
            : (
              ['TH'].includes(trip.source)
                ? String(trip.itineraryId)
                : segment.segmentValue
            ),
      })))
    }

    return itineraries
  }

  // ANCHOR create paxList add-on by data search
  const createPaxList = (search, includeInfant) => {
    const { adult, child, infant } = search
    const paxList = []
    const paxTypes = {
      ADULT: adult,
      CHILD: child,
      ...(includeInfant && { INFANT: infant }),
    }
    Object.entries(paxTypes).forEach(([type, count]) => {
      if (count) {
        paxList.push({
          type,
          count,
        })
      }
    })
    return paxList
  }

  // ANCHOR fetch list ancillary
  const fetchListAncillary = (flightData, dataSearch, bookingId = undefined, isCombination = false) => new Promise((resolve, reject) => {
    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoadingFetchAncillary`, true)
    const payload = {
      source: flightData[0].source,
      paxList: createPaxList(dataSearch, true),
      itineraries: createItineraries(flightData, isCombination),
      bookingId: ['QH'].includes(flightData[0].source) ? flightData[0].fareOptions?.fareValue : bookingId,
    }

    store
      .dispatch('app-flight-v2/fetchListAncillary', payload)
      .then(res => {
        const modifyRes = {
          ssrBags: res.ssrBags.map(i => ({
            ...i,
            source: flightData[0].source,
          })),
          ssrOthers: res.ssrOthers.map(i => ({
            ...i,
            source: flightData[0].source,
          })),
        }
        resolve(modifyRes)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => {
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoadingFetchAncillary`, false)
      })
  })

  const loadingFetchAncillary = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getLoadingFetchAncillary`])

  const fetchSeatMap = (flightData, dataSearch, bookingId = undefined) => new Promise((resolve, reject) => {
    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setLoadingFetchAncillary`, true)
    const payload = {
      source: flightData[0].source,
      paxList: createPaxList(dataSearch, false),
      itineraries: createItineraries(flightData),
      bookingId: ['QH'].includes(flightData[0].source) ? flightData[0].fareOptions?.fareValue : bookingId,
    }

    store
      .dispatch('app-flight-v2/fetchSeatMap', payload)
      .then(res => {
        resolve(res.items)
      })
      .catch(err => {
        reject(err)
      })
  })

  const payNowConfirmPrice = payload => new Promise((resolve, reject) => {
    // if (payload.paxContact === null) delete payload.paxContact
    store
      .dispatch('app-flight-v2/payNowConfirmPrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(err => reject(err))
  })

  const getFareRules = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-v2/getFareRules', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  const calculatePrice = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-v2/calculatePrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  // ANCHOR - GETTER FROM STORE
  const getSearchFlight = computed(
    () => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`],
  )
  const getAddonsData = computed(
    () => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAddonsData`],
  )
  const getSelectedSeatsDetails = computed(
    () => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedSeatsDetails`],
  )
  const getSearchFlightArray = computed(
    () => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`](),
  )

  const currentBreakpoint = computed(
    () => store.getters['app/currentBreakPoint'],
  )

  // ANCHOR: Hành lý màn search
  function fareRulesAirline(airline, cabin) {
    return store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
  }

  function resolveBaggage1G(baggageAllowances, source = '1G') {
    const result = {
      checkinBag: '',
      carryBag: '',
      text: '',
    }
    if (baggageAllowances.length) {
      // checkin
      const bagAllowChecked = baggageAllowances
        .filter(bag => bag?.baggageType === 'CHECKED_BAGGAGE' && bag.passengerTypeCodes.includes('ADULT'))
        .map(bag => {
          const numberBag = bag?.measurement && bag?.quantity && (bag.baggageClass === 'PIECES')
            ? `${bag?.quantity}PC` : (['1A'].includes(source) && bag?.quantity) ? bag.quantity : ''

          const desc = ['1A'].includes(source) && !bag?.measurement?.unit && bag?.freeText
            ? bag.freeText
            : (bag?.measurement?.value
              ? `${bag?.measurement?.value ?? ''}${bag?.measurement?.unit ?? ''}`
              : ''
            ) || (['1A'].includes(source) ? 'Có bao gồm' : '')

          // console.log({
          //   desc,
          //   baggageAllowances: !desc ? baggageAllowances : 'kk',
          //   freeT: bag?.freeText || 'kk',
          // })

          if (['AK', 'TH', '1A'].includes(source)) return desc
          return `${numberBag}${numberBag && desc ? ' x ' : ''}${bag?.quantity !== 0 ? desc : ''}`
        })
      result.checkinBag = Array.from(new Set(bagAllowChecked)).filter(Boolean).join(', ')

      // carry : ẩn hlxt
      const bagAllowCarry = baggageAllowances
        .filter(bag => bag?.baggageType === 'CARRY_ON' && bag.passengerTypeCodes.includes('ADULT') && (source !== '1G'))
        .map(bag => {
          const numberBag = bag?.measurement && bag?.quantity && (bag.baggageClass === 'PIECES') ? `${bag?.quantity}PC` : ''
          const desc = `${bag?.measurement?.value || ''}${bag?.measurement?.unit || ''}` || ''
          if (['AK', 'TH'].includes(source)) return desc
          return `${numberBag}${numberBag && desc ? ' x ' : ''}${bag?.quantity ? desc : ''}`
        })
      result.carryBag = Array.from(new Set(bagAllowCarry)).filter(Boolean).join(', ')
    }

    result.text = `${result.carryBag}${result.carryBag && result.checkinBag ? ' + ' : ''}${result.checkinBag}`
    return result
  }

  function resolveBaggageVN1A(baggageAllowances) {
    const result = {
      checkinBag: '',
      carryBag: '',
      text: '',
    }
    if (baggageAllowances.length) {
      // checkin
      const bagAllowChecked = baggageAllowances
        .filter(bag => bag?.baggageType === 'CHECKED_BAGGAGE' && ['ADULT', 'ALL'].some(it => bag.passengerTypeCodes.includes(it)) && bag.segmentIds.includes('1'))
        .map(bag => {
          if (bag.freeText) {
            return bag.freeText
          }

          const numberBag = bag?.quantity
          const unit = ['PIECES'].includes(bag?.baggageClass) ? (bag?.quantity > 1 ? 'PCs' : 'PC') : ''
          return `${numberBag}${unit}`
        })

      result.checkinBag = Array.from(new Set(bagAllowChecked)).filter(Boolean).reverse().join(', ') // FIXME: reverse cho những chặng có 2 segment

      // Xách tay
      const bagAllowCarry = baggageAllowances
        .filter(bag => bag?.baggageType === 'CARRY_ON' && bag.passengerTypeCodes.includes('ADULT') && bag.segmentIds.includes('1'))
        .map(bag => {
          if (bag.freeText) {
            return bag.freeText
            // .replace(/(\d+)\s+piece?s?(\s*)x(\s*)/gi, (match, p1, p2, p3) => `${p1}PC${p1 > 1 ? 's' : ''}${p2}x${p3}`).replace(/(\d+)\s+Pieces?/gi, '$1PCs')
          }

          const numberBag = bag?.quantity
          const unit = ['PIECES'].includes(bag?.baggageClass) ? (bag?.quantity > 1 ? 'PCs' : 'PC') : ''
          return `${numberBag}${unit}`
        })
      result.carryBag = Array.from(new Set(bagAllowCarry)).filter(Boolean).join(', ')
    }

    // result.carryBag = '' // ẩn hlxt 1G
    result.text = `${result.carryBag}${result.carryBag && result.checkinBag ? ' + ' : ''}${result.checkinBag}`
    return result
  }

  function getBaggageInSearchBooking(trip, isVietnamese = true, isRemoveAccents = false, onlyCheckedBag = true) {
    const result = {
      carryBag: '',
      checkinBag: '',
      text: '',
    }

    // Hết chỗ
    if (!trip.fareOptions.length) return result

    if (trip.domestic) {
      let airline = trip.segments[0].operating || trip.airline
      if (trip.source === 'QH' && [trip.departure.IATACode, trip.arrival.IATACode].includes('VCS')) airline = 'QH_VCS'
      const bag = fareRulesAirline(airline, ['QH', 'VU'].includes(trip.source) ? trip.fareOptions[0].fareType : trip.fareOptions[0].groupClass)
      result.carryBag = bag.boardingBaggage
      result.checkinBag = trip.source === 'QH' && trip.fareOptions[0].bookingClass === 'A' && trip.fareOptions[0].fareType === 'EconomySaver' && moment(trip.departure.at).isBetween('2024-01-24', '2024-02-26')
        ? '30KG' : bag.checkinBaggage
    } else {
      if (['1G', '1A'].includes(trip.source)) {
        const fareItem = trip.fareOptions[0]
        result.checkinBag = resolveBaggage1G(fareItem.baggageAllowances, trip.source).checkinBag
        result.carryBag = resolveBaggage1G(fareItem.baggageAllowances, trip.source).carryBag
      }
      if (trip.source === '1S') {
        const fareItem = trip.fareOptions[0]
        result.carryBag = `${fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.groupClass).boardingBaggage}`
        if (fareItem?.baggageAllowances?.length) {
          result.checkinBag = `${fareItem.baggageAllowances && fareItem.baggageAllowances[0] && fareItem.baggageAllowances[0].numBaggagePieces > 0 ? `${fareItem.baggageAllowances[0].numBaggagePieces}PC` : ''}${fareItem.baggageAllowances && fareItem.baggageAllowances[0] && fareItem.baggageAllowances[0].numBaggagePieces > 1 ? 's' : ''}`
        } else {
          result.checkinBag = `${fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.groupClass).checkinBaggage}`
        }
      }
      if (['VN1A'].includes(trip.source)) {
        const fareItem = trip.fareOptions[0]
        result.carryBag = `${fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.groupClass).boardingBaggage}`
        if (fareItem?.baggageAllowances?.length) {
          const bagCheckin = fareItem.baggageAllowances.find(i => i.baggageType === 'CHECKED_BAGGAGE' && i.passengerTypeCodes.includes('ADULT'))
          result.checkinBag = bagCheckin?.freeText || ''
        }
      }
      if (trip.source === 'VU') {
        const fareItem = trip.fareOptions[0]
        const fareRule = fareRulesAirline('VU', fareItem.fareType)
        result.carryBag = fareRule.boardingBaggage
        result.checkinBag = fareRule.checkinBaggage
      }
      if (trip.source === 'VJ') {
        const fareItem = trip.fareOptions[0]
        let fareRule
        if ([trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ2.includes(item))) {
          fareRule = fareRulesAirline('VJ_2', fareItem.groupClass)
        } else if ([trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ3.includes(item))) {
          fareRule = fareRulesAirline('VJ_3', fareItem.groupClass)
        } else {
          fareRule = fareRulesAirline('VJ', fareItem.groupClass)
        }
        result.carryBag = fareRule.boardingBaggage
        result.checkinBag = fareRule.checkinBaggage
      }
      if (trip.source === 'QH') {
        const fareItem = trip.fareOptions[0]
        let fareRule
        // NOTE QH nhóm 1:
        // - VN đi Úc và Châu Âu  (MEL, SYD, FRA , LGW)
        // - Từ Châu Âu ( trừ Anh ) (FRA)
        // - Từ Anh (LGW)
        // - Từ ÚC (MEL, SYD)
        if ([trip.arrival.IATACode, trip.departure.IATACode].some(item => ['MEL', 'SYD', 'FRA', 'LGW'].includes(item))) {
          fareRule = fareRulesAirline('QH_1', fareItem.fareType)
        }
        // NOTE QH nhóm 2:
        // - Từ Đài Loan (TPE, KHH)
        // - Từ Hàn Quốc (ICN)
        // - Từ Nhật Bản (NRT)
        else if ([trip.departure.IATACode].some(item => ['TPE', 'KHH', 'ICN', 'NRT'].includes(item))) {
          fareRule = fareRulesAirline('QH_2', fareItem.fareType)
        }
        // NOTE QH nhóm 3:
        // - VN đi Đông Nam Á (SIN , BKK)
        else if ([trip.arrival.IATACode].some(item => ['SIN', 'BKK'].includes(item))) {
          fareRule = fareRulesAirline('QH_3', fareItem.fareType)
        }
        //  NOTE QH nhóm 4:
        // - VN đi Đông Bắc Á (TSN , MFM, LJG , TPE , KHH , NRT , ICN)
        else if ([trip.arrival.IATACode].some(item => ['TSN', 'MFM', 'LJG', 'TPE', 'KHH', 'NRT', 'ICN'].includes(item))) {
          fareRule = fareRulesAirline('QH_4', fareItem.fareType)
        }
        //  NOTE QH nhóm 5:
        // - Từ Singapore(SIN) / Thái Lan (BKK)
        else if ([trip.departure.IATACode].some(item => ['SIN', 'BKK'].includes(item))) {
          fareRule = fareRulesAirline('QH_5', fareItem.fareType)
        } else {
          fareRule = fareRulesAirline('QH', fareItem.fareType)
        }
        result.carryBag = fareRule?.boardingBaggage
        result.checkinBag = fareRule?.checkinBaggage
      }
      if (trip.source === 'AK') {
        const fareItem = trip.fareOptions[0]
        result.checkinBag = resolveBaggage1G(fareItem.baggageAllowances).checkinBag
        result.carryBag = resolveBaggage1G(fareItem.baggageAllowances).carryBag
      }
    }

    let checkingBagTemp = !isVietnamese && result.checkinBag === 'Không bao gồm' ? 'Not included' : (isVietnamese && result.checkinBag === 'Not included' ? 'Không bao gồm' : result.checkinBag)
    checkingBagTemp = isRemoveAccents ? removeAccents(checkingBagTemp) : checkingBagTemp

    if (onlyCheckedBag) {
      result.text = `${checkingBagTemp || ''}`
    } else {
      result.text = `${result.carryBag || ''}${result.carryBag && result.checkinBag ? ' + ' : ''}${checkingBagTemp || ''}`
    }

    if (!isVietnamese) {
      result.carryBag = result?.carryBag
        ?.replaceAll('pc', 'PC')
        ?.replaceAll('kiện', 'PC')
        ?.replaceAll('KIỆN', 'PC')
        ?.replaceAll('Không bao gồm', 'Not included')
        ?.replaceAll('Khong bao gom', 'Not included')

      result.checkinBag = result?.checkinBag
        ?.replaceAll('pc', 'PC')
        ?.replaceAll('kiện', 'PC')
        ?.replaceAll('KIỆN', 'PC')
        ?.replaceAll('Không bao gồm', 'Not included')
        ?.replaceAll('Khong bao gom', 'Not included')
    }

    if (isRemoveAccents) {
      result.carryBag = removeAccents(result.carryBag)
      result.checkinBag = removeAccents(result.checkinBag)
    }

    return result
  }

  const loading = ref(false)
  function retrieveVisa(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store.dispatch('app-flight-v2/retrieveVisa', payload)
        .then(res => resolve(res))
        .catch(error => {
          reject(error)
        })
        .finally(() => { loading.value = false })
    })
  }

  function getCountriesName(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store.dispatch('app-flight-v2/getCountriesName', payload)
        .then(res => resolve(res.data.items))
        .catch(error => {
          reject(error)
        })
        .finally(() => { loading.value = false })
    })
  }

  const countriesList = ref([])
  const countriesListDefault = ref([])

  const openGetCountries = () => {
    if (countriesListDefault.value.length === 0) {
      getCountriesName({ searchText: 'V' })
        .then(res => {
          countriesList.value = res
          countriesListDefault.value = res
        })
    } else {
      countriesList.value = countriesListDefault.value
    }
  }

  const searchGetCountries = debounce(searchText => {
    if (searchText) {
      getCountriesName({ searchText }).then(res => {
        countriesList.value = res
        countriesListDefault.value = res
      })
    }
  }, 300)

  function convertShortenAircraft(aircraftCode) {
    const fullName = store.getters['globalConfig/getAirCraftByIata'](aircraftCode)
    const firstLetter = fullName[0]
    const azRegex = /^[a-zA-Z]*$/
    if (azRegex.test(firstLetter) && !azRegex.test(aircraftCode[0])) {
      return `${firstLetter}${aircraftCode}`
    }
    return aircraftCode
  }

  function mappingPenalties(penalties) {
    const resultArray = penalties
      .map(item => `<span>${formatCurrency(item.amount)} ${item?.currency || ''} (${this.$te(`flight.fareRuleTimeStatus.${item.applies}`) ? this.$t(`flight.fareRuleTimeStatus.${item.applies}`) : item.applies})</span>`)
    const resultSet = new Set(resultArray)
    return Array.from(resultSet).join(', ')
  }

  function resolveQHInternational(departure, arrival) {
    // NOTE QH nhóm 1:
    // - VN đi Úc và Châu Âu  (MEL, SYD, FRA , LGW)
    // - Từ Châu Âu ( trừ Anh ) (FRA)
    // - Từ Anh (LGW)
    // - Từ ÚC (MEL, SYD)
    if ([arrival, departure].some(item => ['MEL', 'SYD', 'FRA', 'LGW'].includes(item))) return 'QH_1'
    // NOTE QH nhóm 2:
    // - Từ Đài Loan (TPE, KHH)
    // - Từ Hàn Quốc (ICN)
    // - Từ Nhật Bản (NRT)
    if ([departure].some(item => ['TPE', 'KHH', 'ICN', 'NRT'].includes(item))) return 'QH_2'
    // NOTE QH nhóm 3: - VN đi Đông Nam Á (SIN , BKK)
    if ([arrival].some(item => ['SIN', 'BKK', 'DMK'].includes(item))) return 'QH_3'
    // NOTE QH nhóm 4: - VN đi Đông Bắc Á (TSN , MFM, LJG , TPE , KHH , NRT , ICN)
    if ([arrival].some(item => ['TSN', 'MFM', 'LJG', 'TPE', 'KHH', 'NRT', 'ICN'].includes(item))) return 'QH_4'
    // NOTE QH nhóm 5: - Từ Singapore(SIN) / Thái Lan (BKK)
    if ([departure].some(item => ['SIN', 'BKK', 'DMK'].includes(item))) return 'QH_5'
    return 'QH'
  }

  function checkDeniedGroupFare(tripListsSelected, indexItinerarySelecting, isGroupedItineraryResponseSelecting = false, sourceSelecting) {
    const sourcesEqualRequired = ['VN1A', 'QH']
    const validTrips = tripListsSelected.filter(Boolean)
    const isConflict = tripSelected => (tripSelected.isGroupedItineraryResponse && sourcesEqualRequired.includes(tripSelected.source) && sourceSelecting !== tripSelected.source)
    || (isGroupedItineraryResponseSelecting && (!sourcesEqualRequired.includes(tripSelected.source) || (sourcesEqualRequired.includes(tripSelected.source) && sourceSelecting !== tripSelected.source)))

    if (validTrips.length === 1) {
      const valIndex = tripListsSelected.findIndex(trip => trip)
      if (valIndex === indexItinerarySelecting) return false
      return validTrips.some(isConflict)
    }
    return validTrips.some(isConflict)
  }

  function getSelectedSourceName(source, selectingSource) {
    const SOURCE_NAME = {
      VN1A: 'VIETNAM AIRLINES',
      QH: 'BAMBOO AIRWAYS',
    }
    const validSource = ['VN1A', 'QH'].find(s => s === source || s === selectingSource)
    if (validSource) return SOURCE_NAME[validSource]
    return ''
  }

  function checkDateTimeMultiCityFlight(dates) {
    for (let i = 1; i < dates.length; i += 1) {
      const currentDate = moment(dates[i])
      for (let j = 0; j < i; j += 1) {
        const previousDate = moment(dates[j])
        if (currentDate.isBefore(previousDate)) {
          return i + 1
        }
      }
    }
    return false
  }

  return {
    // search
    searchFlightv2,
    searchClassBooking,
    searchMoreResultClassBooking,
    calculatePriceClassBooking,
    getClassBookingInfoTrip,
    getAirportsByCodeList,

    // airport
    fetchAirports,
    fetchAirportGroup,
    getAirportGroup,
    getAirportByAirportCode,

    // create booking
    draftBooking,
    createBooking,
    commandCreateBooking,
    createGroupBooking,
    payNowConfirmPrice,

    // add-on
    getAddonsData,
    setDefaultDataPaxSeat,
    fetchListAncillary,
    loadingFetchAncillary,
    fetchSeatMap,
    // results
    fetchCheapestFare,
    tabIndex,
    isDomestic,
    airlineRoundtrip,
    combinationKeys,
    checkDeniedGroupFare,
    getSelectedSourceName,

    // generals
    resetStore,
    getLoading,
    statusJobDone,
    FLIGHT_APP_STORE_MODULE_NAME,
    processValue,
    clearIntervalIDs,
    getSearchFlight,

    getSelectedSeatsDetails,
    getFareRules,
    getSearchFlightArray,
    calculatePrice,

    // baggage
    getBaggageInSearchBooking,

    // VISA
    retrieveVisa,
    getCountriesName,
    countriesList,
    openGetCountries,
    searchGetCountries,

    currentBreakpoint,
    convertShortenAircraft,
    resolveBaggage1G,
    resolveBaggageVN1A,
    mappingPenalties,
    resolveQHInternational,
    iataCodeVJ2,
    iataCodeVJ3,
    fareRulesAirline,

    checkDateTimeMultiCityFlight,
  }
}

// utils
// hàm gộp 2 hoặc nhiều fareOptions để lấy giá tổng (Dùng cho giá kết hợp)
export const combinateFareOptions = arrayFareOption => {
  let mergedObj = {}

  arrayFareOption.forEach((obj, index) => {
    if (index === 0) {
      mergedObj = cloneDeep(obj)
    } else {
      Object
        .keys(obj ?? {})
        .forEach(key => {
          // skip
          if (['passenger', 'seatAvailable', 'source'].includes(key)
            || (['agencyFee', 'totalAgencyFee'].includes(key) && mergedObj?.isAgencyFeeOnce) // GDS chỉ tính 1 lần phí xuất vé/vé
          ) return
          switch (typeof mergedObj[key]) {
            case 'number':
              mergedObj[key] = (mergedObj[key] || 0) + obj[key]
              break
            case 'string':
              mergedObj[key] = `${mergedObj[key]}${mergedObj[key] ? ' | ' : ''}${obj[key]}`
              break
            case 'object':
              if (Array.isArray(mergedObj[key])) {
                mergedObj[key] = mergedObj[key].concat(obj[key])
              } else {
                mergedObj[key] = combinateFareOptions([mergedObj[key], obj[key]])
              }
              break
            default:
              break
          }
        })
    }
  })
  return mergedObj
}
